import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";
import moment from 'moment'
import { mounted } from "vue-simple-suggest";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    DatePicker,
    Paginate
  },
  data: function() {
    return {
      busy: false,
      date_start: null,
      date_end: null,
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'กรุณาระบุวันที่และกดปุ่มแสดงข้อมูลเพื่อค้นหาข้อมูล',
      fields: [
        { 
          key: 'index', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'employee_code', 
          label: 'วันที่', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'branch', 
          label: 'สาขา', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'code', 
          label: 'รหัสสินค้า', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'name', 
          label: 'ชื่อสินค้า', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'name', 
          label: 'ความหนา                ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'name', 
          label: 'สี', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'name', 
          label: 'น้ำหนัก', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'name', 
          label: 'จำนวน', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'td-top text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'total_price', 
          label: 'ยอดเงิน ฿', 
          sortable: true,
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'font-weight-bold text-black td-top text-xs-1/2',
          class: 'text-right' 
        }
      ],
      items: [

      ],
      form: {
        sale_id: null,
        start_date: new Date(moment().startOf('month').format('YYYY-MM-DD')),
        end_date: new Date(moment().endOf('month').format('YYYY-MM-DD'))
      },
      seller_list: [],
      sum: 0,
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  methods: {
    async onInitData() {
      try {
        const item = this.items
        this.items = []
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'

        const res = await this.$axios.get(
          this.$host + "/api/v1/report/coiltransaction?sale_id=" + (this.form.sale_id ? this.form.sale_id : "") + "&keyword_startdate="+moment(this.form.start_date).format('YYYY-MM-DD')+"&keyword_enddate="+moment(this.form.end_date).format('YYYY-MM-DD'),
                )
        
        const data = res.data

        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.items = data.data
        this.sum = data.sum

      } catch (error) {
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    },
    exportPDFReport() {
      this.loading = true;
      setTimeout(async () => {
        this.loading = true

        const result = await this.$axios.get(
          this.$host + "/api/v1/report/pdf/daily?sale_id=" + (this.form.sale_id ? this.form.sale_id : "") + "&keyword_startdate="+moment(this.form.start_date).format('YYYY-MM-DD')+"&keyword_enddate="+moment(this.form.end_date).format('YYYY-MM-DD'),

          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: "blob",
          }
        );
        const blob = new Blob([result.data], { type: "application/pdf" });
        const objectUrl = URL.createObjectURL(blob);
        var isSafari = /^((?!chrome|android).)*safari/i.test(
          navigator.userAgent
        );
        if (isSafari) {
          window.open(objectUrl)
          // const link = document.createElement('a')
          // link.href = objectUrl2
          // link.setAttribute('download', 'ใบเสนอราคา ' +  + '.pdf')
          // document.body.appendChild(link)
          // link.click()

          this.loading = false;
        } else {
          window.open(
            objectUrl,
            "targetWindow",
            `toolbar=no,
           location=no,
           status=no,
           menubar=no,
           scrollbars=yes,
           resizable=yes,
           width=SomeSize,
           height=SomeSize`
          );
          this.loading = false;
        }
        // URL.revokeObjectURL(objectUrl);
      }, 1000);
    },
  },
  async mounted(){
  }
}